import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { computed } from 'vue';
import { useStore } from 'vuex';

// constants
import { StockStatus } from '@/constants/statuses/returnsStatuses';

// components
import { ToyTemplate } from '@/components/features';

// types
import type { ComputedRef } from 'vue';
import type { ProductItem } from '@/types/product';


export default /*@__PURE__*/_defineComponent({
  __name: '_toy',
  setup(__props) {

const store = useStore();

const product: ComputedRef<ProductItem> = computed(() => store.state.returnOrder.returnOrderItem);

const addStatus = (status: StockStatus): void => {
  store.dispatch('returnOrder/setReturnStatus', status);
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(ToyTemplate), {
    product: product.value,
    onAddStatus: addStatus
  }, null, 8, ["product"]))
}
}

})